import { Component, OnInit } from '@angular/core';
import { ErrorService } from 'src/app/services/error.service';

@Component({
  selector: 'app-error-banner',
  template: `
    <div *ngIf="errorMessage" class="error-banner">
      {{ errorMessage }}
    </div>
  `,
  styles: [`
    .error-banner {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      background-color: red;
      color: white;
      text-align: center;
      padding: 1em;
      z-index: 1000;
    }
  `]
})
export class ErrorBannerComponent implements OnInit {
  errorMessage: string | null = null;

  constructor(private errorService: ErrorService) {}

  ngOnInit() {
    this.errorService.error$.subscribe(message => {
      this.errorMessage = message;
    });
  }
}