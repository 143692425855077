import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SalesService } from 'src/app/services/sales.service';
import { Location } from "@angular/common";
import { EventsManager } from "../../../events/events.manager";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})

export class SignInComponent implements OnInit {
  @Input() public userInfo: any = {};
  @ViewChild("otpBox1") otpBox1 : ElementRef | undefined;
  @ViewChild("otpBox2") otpBox2 : ElementRef | undefined;
  @ViewChild("otpBox3") otpBox3 : ElementRef | undefined;
  @ViewChild("otpBox4") otpBox4 : ElementRef | undefined;
  @ViewChild("otpBox5") otpBox5 : ElementRef | undefined;
  @ViewChild("otpBox6") otpBox6 : ElementRef | undefined;
  @ViewChild("otpBox7") otpBox7 : ElementRef | undefined;
  @ViewChild("otpBox8") otpBox8 : ElementRef | undefined;

  title: string = "Email already exists";
  signInOption: string = "message";
  rememberLogin: boolean = true;
  showEmailExistsModal: boolean = true;
  showVerificationModal: boolean = false;
  isAuthenticated: boolean = true;
  timerWindow: number = 300;  //seconds
  timer: number = this.timerWindow;  //seconds
  isTimerOn: boolean = false;
  otp1: string = '';
  otp2: string = '';
  otp3: string = '';
  otp4: string = '';
  otp5: string = '';
  otp6: string = '';
  otp7: string = '';
  otp8: string = '';
  disableVerify: boolean = false;
  showUserNotActivated: boolean = false;
  otpReceived = false;
  memberPortalSignIn: string = environment.memberPortalLink;
  promise: any = {};
  
  constructor(
    public modal: NgbActiveModal,
    private salesService: SalesService,
    private location : Location,
    private em: EventsManager
  ) {
  }

  ngOnInit(): void {
    this.checkIfUserIsActivated();
  }
    
  checkIfUserIsActivated() {
    if(!this.userInfo.userName) {
        this.showUserNotActivated = true;
        this.salesService
        .sendActivationEmail({userId: this.userInfo.id, benefitPlanId: null})
        .subscribe(() => {
        });
    }
  }

  continue() {
    this.showEmailExistsModal = false;
    this.showVerificationModal = true;
    this.title = "Verification";
    this.generateOTP();
  }
  
  start() {            
    this.timer = this.timerWindow;  //seconds
    this.isTimerOn = true;
    this.promise = setInterval(() => {
        this.timer--;
        if(this.timer == 0) {
            clearInterval(this.promise);
            this.isTimerOn = false;
        }
    }, 1000);
  }

  clearOptBoxes() {
    this.otp1 = '';
    this.otp2 = '';
    this.otp3 = '';
    this.otp4 = '';
    this.otp5 = '';
    this.otp6 = '';
    this.otp7 = '';
    this.otp8 = '';
    this.otpBox1?.nativeElement.focus();
  }

  signInOptionClick() {
    this.clearOptBoxes();
    this.otpReceived = false;
    this.isAuthenticated = true;
    this.isTimerOn = false;
  }

  generateOTP() { 
    this.clearOptBoxes(); 
    this.otpChanged();
    var sendSms = true;
    this.isAuthenticated = true;
    if(this.signInOption === 'mail') {
        sendSms = false;
    }
    this.salesService
    .generateOTP({userName: this.userInfo.emailAddress, sendSms: sendSms})
    .subscribe(() => {
        this.start();
        this.otpReceived = true;
        this.otpBox1?.nativeElement.focus();
    });
  }

  authenticateOTP() {
    this.isAuthenticated = false;
    this.setUserName('');
    this.salesService
    .authenticateOTP({userName: this.userInfo.emailAddress, oneTimePassword: this.otp1 + this.otp2 + this.otp3 + this.otp4 + this.otp5 + this.otp6 + this.otp7 + this.otp8, rememberLogin: this.rememberLogin})
    .subscribe((results: any) => {
        this.setUserName(results.userName);
        var userName = this.getUserName();
        if (userName != null && userName != '') {
            this.isAuthenticated = true;
            var url = environment.memberPortalLink + '?otp_token=' + results.otpToken;
            window.location.replace(url);
            this.modal.close('closed')
        }
        else {
            this.isAuthenticated = false;
            this.otpReceived = false;
            this.isTimerOn = false;
        }
    });
  }

  signInMemberPortal() {
    window.open(this.memberPortalSignIn, "_blank");
  }

  otpKeyup(otpBox: HTMLInputElement, nextOtpBox: HTMLInputElement) {
    if(otpBox.value != "") {
      nextOtpBox.value = "";
      nextOtpBox.focus();
      this.otpChanged();
    }
  }

  otpChanged() {
    if(this.otp1 === "" || this.otp2 === "" || this.otp3 === "" || this.otp4 === "" || this.otp5 === "" || this.otp6 === "" || this.otp7 === "" || this.otp8 === "") {
        this.disableVerify = true;
    }
    else {
        this.disableVerify = false;
    }
  }

  getUserName() {
    return this.userInfo.userName;
  }

  setUserName(name: string) {
    this.userInfo.userName = name;
  }
}
