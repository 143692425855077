<div id="sign-in-modal" class="modal fade show d-block modal-wf-lg">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <div class="header-text">
            {{title}}
          </div>
          <div class="close-img">
            <img src="assets/images/close.png" alt="Close" (click)="modal.close('closed')"/>
          </div>
        </div>
        <div class="modal-body">
                <div class="row">
                    <div *ngIf="showEmailExistsModal" class="col-12">
                      <p>{{userInfo.emailAddress}}</p>
                        <div *ngIf="showUserNotActivated">
                            <p>Your account is in need of activation to complete your transaction. Please log into your e-mail and follow the prompts to activate your account. In this same location, you can make the purchase for your plan.</p>
                            <div style="display: flex;justify-content: center;">
                                <button type="button" class="submit-btn" (click)="modal.close('closed')">Ok</button>
                            </div>
                        </div>
                        <div *ngIf="!showUserNotActivated">
                            <div class="col-12">
                                <p><a href="" (click)="signInMemberPortal()">Sign In</a><span> to your Member Portal</span> or you can request a verification code to sign in.</p>
                            </div>
                            <div class="col-12">
                                <input id="message" type="radio" name="radio" value="message" [(ngModel)] ="signInOption" (click)="signInOptionClick()"
                                    style="height: auto;display: inline;">
                                <label for="message" style="font-size: medium;"> &nbsp;Verification code will be sent to (xxx) xxx-{{userInfo.phoneNumber.substr(userInfo.phoneNumber.length - 4,4)}}</label>
                            </div>
                            <div class="col-12">
                                <input id="mail" type="radio" name="radio" value="mail" [(ngModel)] ="signInOption" (click)="signInOptionClick()"
                                style="margin-top:15px;height: auto;display: inline;">
                                <label for="mail"> &nbsp;Verification code will be sent to {{userInfo.emailAddress}}</label>
                            </div>
                            <div class="col-12">
                                <input style="margin-top:50px;height: auto;display: inline;" type="checkbox" id="keepSignIn" [checked]="rememberLogin">&nbsp;<label for="keepSignIn">Keep me signed in</label>&nbsp; <label style="font-size: smaller;"> (Uncheck if using public device.)</label>
                            </div>
                            <div class="btn-container">
                                <button type="button" class="submit-btn" (click)="continue()">CONTINUE</button>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="showVerificationModal" class="col-12 subheader-layout">
                        <div>
                            <div style="font-size: medium;margin-bottom: 30px;text-align: center;">
                                <span *ngIf="isTimerOn && signInOption == 'message'">Verification code has been sent to (xxx) xxx-{{userInfo.phoneNumber.substr(userInfo.phoneNumber.length - 4,4)}}</span>
                                <span *ngIf="isTimerOn && signInOption == 'mail'">Verification code has been sent to {{userInfo.emailAddress}}</span>
                            </div>
                            <input #otpBox1 matInput style="width:40px;display: inline;" (keyup)="otpKeyup(otpBox1, otpBox2)" maxlength="1" [(ngModel)] ="otp1"/> <!--type="password" -->
                            <input #otpBox2 matInput style="width:40px;margin-left: 10px;display: inline;" (keyup)="otpKeyup(otpBox2, otpBox3)" maxlength="1" [(ngModel)] ="otp2"/>
                            <input #otpBox3 matInput style="width:40px;margin-left: 10px;display: inline;" (keyup)="otpKeyup(otpBox3, otpBox4)" maxlength="1" [(ngModel)] ="otp3"/>
                            <input #otpBox4 matInput style="width:40px;margin-left: 10px;display: inline;" (keyup)="otpKeyup(otpBox4, otpBox5)" maxlength="1" [(ngModel)] ="otp4"/>
                            <input #otpBox5 matInput style="width:40px;margin-left: 10px;display: inline;" (keyup)="otpKeyup(otpBox5, otpBox6)" maxlength="1" [(ngModel)] ="otp5"/>
                            <input #otpBox6 matInput style="width:40px;margin-left: 10px;display: inline;" (keyup)="otpKeyup(otpBox6, otpBox7)" maxlength="1" [(ngModel)] ="otp6"/>
                            <input #otpBox7 matInput style="width:40px;margin-left: 10px;display: inline;" (keyup)="otpKeyup(otpBox7, otpBox8)" maxlength="1" [(ngModel)] ="otp7"/>
                            <input #otpBox8 matInput style="width:40px;margin-left: 10px;display: inline;" (keyup)="otpChanged()" maxlength="1" [(ngModel)] ="otp8"/>
                            <div class="error-message">
                                <span *ngIf="!isAuthenticated">Invalid verification code</span>
                            </div>
                            <div style="font-size: medium;margin-top: 30px;text-align: center;">
                                <span *ngIf="!isTimerOn">I didn't receive a code <span (click)="generateOTP()" style="color: var(--Primary-100, #009DFF);font-weight: 400; cursor: pointer;">Resend</span></span>
                            </div>
                            <div *ngIf="otpReceived && isTimerOn">
                                <span style="text-align: center;"><img style="margin-left: 30px;height: 25px;" id="timer-icon" src="/assets/images/timer.svg" />
                                    <span style="font-size: medium;"> {{timer | timer | date:'m:ss'}}   </span>
                                    <span style="font-size: smaller;">(You should have received {{signInOption}}.)</span>
                                </span>
                            </div>
                            <div class="btn-container">
                                <button type="button" class="submit-btn" (click)="authenticateOTP()">VERIFY</button> <!-- [disabled]="disableVerify"-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
