import { Component, OnInit } from '@angular/core';
import {UtilService} from "../../services/util.service";

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss']
})
export class TermsOfUseComponent implements OnInit {
content:string = '';
  constructor(private utilSrvc:UtilService) { }

  ngOnInit(): void {
    this.utilSrvc.getTermsOfUse().subscribe(
      (result) => {
        this.content = result;
      }
    );
  }

}
