import {Component, Input, OnInit} from '@angular/core';
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {LandingComponent} from "../../landing/landing.component";
import {InviteAFriendComponent} from "../../modals/invite-a-friend/invite-a-friend.component";
import {EventsManager} from "../../../events/events.manager";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-subheader',
  templateUrl: './subheader.component.html',
  styleUrls: ['./subheader.component.scss']
})
export class SubheaderComponent implements OnInit {
office: any = {};
environment:any = environment;
  constructor(private em: EventsManager, public modalService: NgbModal) { }

  ngOnInit(): void {
    this.office = this.em.getOfficeInfo();
    this.em.officeUpdate.subscribe(
      (result) => {
        this.office = result;
      }
    )
  }

  inviteAFriend(){
    const modalRef: NgbModalRef = this.modalService.open(InviteAFriendComponent);
    modalRef.result.then(
      (result: any) => {
        this.closeModal();
      },
      error => {
        console.log('error: ',error);
      }
    )
  }

  closeModal() {
    this.modalService.dismissAll();
  }
}
