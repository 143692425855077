<div class="row mb-4" [formGroup]="memberForm">
  <div class="col-12" *ngIf="max > 0 && !isMinor">
    <input type="radio" id="member{{memberNum}}" name="isPrimary" formControlName="isPrimary" value="{{memberNum}}" (change)="setPrimary(memberNum)" required>
    <label for="member{{memberNum}}" class="radio-label"> &nbsp;Is Primary Member</label>
    <img src="assets/images/delete.svg" class="float-end" *ngIf="showDelete && ((memberNum+1) > min)" (click)="removeMe()"/>
  </div>
  <div class="col-12">
    <div class="row">
      <div class="col-lg-6 col-12">
        <label for="firstName{{memberNum}}">First Name</label>
        <input type="text" name="firstName{{memberNum}}" id="firstName{{memberNum}}" formControlName="firstName{{memberNum}}" class="text-input" minlength="2" required>
        <div *ngIf="memberForm.get('firstName'+memberNum)?.dirty && memberForm.get('firstName'+memberNum)?.invalid" class="warning">
          First Name is required
        </div>
      </div>
      <div class="col-lg-6 col-12">
        <label for="lastName{{memberNum}}">Last Name</label>
        <input type="text" name="lastName{{memberNum}}" id="lastName{{memberNum}}" formControlName="lastName{{memberNum}}" class="text-input" minlength="2" required>
        <div *ngIf="memberForm.get('lastName'+memberNum)?.dirty && memberForm.get('lastName'+memberNum)?.invalid" class="warning">
          Last Name is required
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-12">
        <label for="dob{{memberNum}}">Date of Birth</label>
        <input type="text" name="dob{{memberNum}}" id="dob{{memberNum}}" class="text-input" formControlName="dob{{memberNum}}" (keyup)="dateFormat($event)" (blur)="checkForIsMinor($event)" minlength="3" required>
        <div *ngIf="memberForm.get('dob'+memberNum)?.dirty && memberForm.get('dob'+memberNum)?.invalid" class="warning">
          <div class="mt-1" *ngFor="let error of memberForm.get('dob'+memberNum)?.errors | objToKeys">
            {{translateError(error)}}
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-12">

      </div>
    </div>
    <div class="row" *ngIf="!isMinor">
      <div class="col-lg-6 col-12">
        <label for="email{{memberNum}}">Email</label>
        <input type="text" name="email{{memberNum}}" id="email{{memberNum}}" class="text-input" formControlName="email{{memberNum}}" (blur)="checkForUser()" minlength="3" required>
        <div *ngIf="memberForm.get('email'+memberNum)?.dirty && memberForm.get('email'+memberNum)?.invalid" class="warning">
          Email is required
        </div>
      </div>
      <div class="col-lg-6 col-12">
        <label for="phone{{memberNum}}">Phone Number</label>
        <input type="text" name="phone{{memberNum}}" id="phone{{memberNum}}" formControlName="phone{{memberNum}}" class="text-input" mask="(000)000-0000" minlength="10" required>
        <div *ngIf="memberForm.get('phone'+memberNum)?.dirty && memberForm.get('phone'+memberNum)?.invalid" class="warning">
          Phone Number is required
        </div>
      </div>
    </div>
    <div class="row" *ngIf="isMinor">
      <div class="col-12 col-12-lg">
        Member is a minor
      </div>
    </div>
    <input type="hidden" formControlName="minor{{memberNum}}" value="false">
  </div>
</div>
