<div class="container mb-5">
  <div class="row mt-5">
    <div class="col-12">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_245_1774)">
          <path d="M15.41 16.59L10.83 12L15.41 7.41L14 6L8 12L14 18L15.41 16.59Z" fill="#009DFF"/>
        </g>
        <defs>
          <clipPath id="clip0_245_1774">
            <rect width="24" height="24" fill="white"/>
          </clipPath>
        </defs>
      </svg>
      <label class="back" routerLink="/support">Back</label>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <app-card-container>
        <div class="row mt-2">
          <div class="col-12">
            <div class="header-text">Member Fee Schedule</div>
          </div>
        </div>
        <div class="row mt-5 mb-3">
          <div class="col-12">
            <span *ngFor="let template of selectedPlan.coverageLevels; let i = index ">
              <a class="bubble" [class]="i == currentCovLevel ? 'active' : ''" (click)="getFeeSchedule(i)" style="margin-right: 30px;">{{template.name}}</a>
            </span>
          </div>
        </div>
        <div class="row mt-5 mb-3">
          <div class="col-12" *ngIf="footer != 'X%'">
            <div>The Member Savings are exclusive to services rendered by {{selectedPlan.name}} participating dental offices.
              Any fees not listed on this member fee schedule are discounted at a minimum of {{footer}} from the participating offices retail fees.</div>
          </div>
        </div>
        <div class="row mt-5 mb-3">
          <div class="col-6 table-title text-bold">Procedure Description</div>
          <div class="col-6 table-title text-bold text-right">Member Savings</div>
          <div class="col-12 border-gray mt-2"></div>
        </div>
        <div class="row mt-5 mb-3" *ngFor="let item of details.salesPlanMarketingDetails?.default ; let ii = index ">
          <div *ngIf="item.itemName != 'Footer'">
            <div class="row">
              <div class="col-12 section-header mt-3 mb-2 text-bold">{{item.itemName}}</div>
            </div>
            <div class="row mt-2" *ngFor="let items of item.value ; let a = index">
              <div class="col-6 mb-3">{{items.column1}}</div>
              <div class="col-6 mb-3 text-right">{{items.column2}}</div>
              <div class="col-12 border-gray"></div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="!details.salesPlanMarketingDetails">
          <div class="col-12">No Fee Schedule Available</div>
        </div>
        <div class="row mt-5 mb-3">
          <div class="col-12 col-lg-12 mb-3 sub-text2">
            *Member savings may vary by location. Free exams and x-rays have no limitations. Any services not listed are available at a minimum of {{footer}} off the office’s Retail Fee(s).
          </div>
          <div class="col-12 col-lg-12 mb-3 sub-text">
            You are responsible for payment of the dentist’s normal fees for any of the following limited services:
          </div>
          <div class="col-12 col-lg-12 mb-3 sub-text2">
            Replacement of partial dentures is limited to one per arch every two years. <br>
            Replacement of full upper and lower dentures are limited to one per arch every two years. <br>
            Replacement of fixed prosthetics such as crowns, bridges, inlays, and onlays is limited to once every year.
          </div>
        </div>
      </app-card-container>
    </div>
  </div>
</div>
<app-legal-text [officeData]="plans"></app-legal-text>
