import {Component, Input, OnInit} from '@angular/core';
import {EventsManager} from "../../../events/events.manager";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {SignInComponent} from "../../modals/sign-in/sign-in.component";
import {SalesService} from 'src/app/services/sales.service';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {phoneValidator, ValidateBirthdate} from "../../validators/form-validator";
import {MemberModel} from "../../../models/member.model";
import {RangePipe} from "../../../pipes/range";

@Component({
  selector: 'app-multi-member',
  templateUrl: './multi-member.component.html',
  styleUrls: ['./multi-member.component.scss']
})
export class MultiMemberComponent implements OnInit {
@Input() level:any = {};
primary:string = '';
primaryApplicant:any = {};
applicants:any = [];
showForm:boolean = false;
currentCount:number = 0;
maxReached:boolean = false;
showDeleteBtn: boolean = false;
minorCount:number = 0;

multiMemberForm:FormGroup = new FormGroup({})
  constructor(private em: EventsManager, public modalService: NgbModal, private salesService: SalesService, private router:Router, private fb: FormBuilder) { }

  ngOnInit(): void {
    window.scrollTo(0,0);
    this.salesService.getPurchase(false).subscribe(
      (result:any) => {
        this.em.purchaseInfo = result;
      }
    );

    this.createListeners();

    this.buildControls(this.level.min);
  }

  createListeners(){
    this.em.getwWhoIsPrimary.subscribe(
      (result) => {
        this.primary = result;
      }
    );

    this.em.memberCountUpdate.subscribe(
      (result) => {

        this.updateMemberCount();
      }
    );

    this.em.updateForm.subscribe(
      (result) => {
        this.processForm();
      }
    );

    this.em.hasMinor.subscribe(
      (result) => {
        this.minorCount++;
        this.em.setMinorCount(this.minorCount);
        if(this.minorCount >= this.level.max){
          this.multiMemberForm.get('isPrimary')?.removeValidators([Validators.required]);
          this.multiMemberForm.removeControl('isPrimary');
          this.minorCount = this.level.max;
        }
      }
    )
  }

  buildControls(howMany:number){
    for(let i:number = 0;i < howMany;i++){
      this.currentCount = i;
      this.buildControlSet(i,false);
    }
    this.currentCount =  howMany;
    if(this.multiMemberForm.controls){
      this.showForm = true;
    }
  }

  buildControlSet(num:number,updateCount:boolean){
    this.multiMemberForm.addControl('firstName'+num,new FormControl('', [Validators.required,Validators.minLength(2)]));
    this.multiMemberForm.addControl('lastName'+num, new FormControl('', [Validators.required,Validators.minLength(2)]));
    this.multiMemberForm.addControl('email'+num, new FormControl('', [Validators.required,Validators.email]));
    this.multiMemberForm.addControl('dob'+num,  new FormControl('', [Validators.required, ValidateBirthdate]));
    this.multiMemberForm.addControl('phone'+num, new FormControl('', [Validators.required,phoneValidator]));
    this.multiMemberForm.addControl('minor'+num, new FormControl('false'));
    if(this.level.min > 1){
      this.multiMemberForm.addControl('isPrimary', new FormControl(''));
    }


    if(updateCount){
      this.multiMemberForm.updateValueAndValidity();
      this.currentCount++;
      if(this.currentCount > this.level.min){
        this.showDeleteBtn = true;
      }
      this.maxReached = this.currentCount === this.level.max;
    }
  }

  get members(){
    return this.multiMemberForm.get('members.details') as FormArray;
  }
  addMember(){
    let newCount:number = this.currentCount;
    this.buildControlSet(newCount,true);
  }

  updateMemberCount() {
      this.multiMemberForm.updateValueAndValidity();
      this.currentCount--;
      this.showDeleteBtn = this.currentCount > this.level.min;
      this.maxReached = this.currentCount === this.level.max;
  }
  processForm(){
    if(this.currentCount === 1){
    //  console.log('we only have one');
      this.createPrimaryMember(0);

    } else {
     // console.log('--- more than 1 ---');
      for(let i:number = 0;i < this.currentCount;i++){
        if(this.multiMemberForm.get('isPrimary')){
       //   console.log('we have a primary radio button')
          if(this.multiMemberForm.get('isPrimary')?.value == i){
         //   console.log('we found the primary so create it')
            this.createPrimaryMember(i);
          } else {
          //  console.log('have a secondary so create them')
            this.createSecondaryMember(i);
          }
        } else {
        //  console.log('only have a secondary');
          this.createSecondaryMember(i);
        }
      }
    }

    let applicantsToSave:any = {};
    applicantsToSave.primaryApplicant = this.primaryApplicant;
    applicantsToSave.secondary = this.applicants;
    this.em.setApplicants(applicantsToSave);
    this.em.togglePrimary.emit(true);
  }

  createPrimaryMember(which:number){
    let email:string = this.multiMemberForm.get('email'+which)?.value;
    let phone:string = this.multiMemberForm.get('phone'+which)?.value;
    if(this.multiMemberForm.get('minor'+which)?.value === 'true'){
      email = '';
      phone = '';
    }
    this.primaryApplicant.firstName = this.multiMemberForm.get('firstName'+which)?.value;
    this.primaryApplicant.lastName = this.multiMemberForm.get('lastName'+which)?.value;
    this.primaryApplicant.birthDate = this.multiMemberForm.get('dob'+which)?.value;
    this.primaryApplicant.email = email;
    this.primaryApplicant.phoneNumber = phone;
  }

  createSecondaryMember(which:number){
    let email:string = this.multiMemberForm.get('email'+which)?.value;
    let phone:string = this.multiMemberForm.get('phone'+which)?.value;
    if(this.multiMemberForm.get('minor'+which)?.value === 'true'){
      email = '';
      phone = '';
    }
    let secondary:MemberModel = {
      firstName: this.multiMemberForm.get('firstName'+which)?.value,
      lastName: this.multiMemberForm.get('lastName'+which)?.value,
      birthDate: this.multiMemberForm.get('dob'+which)?.value,
      emailAddress: email,
      phoneNumber: phone,
      isPrimary: false
    };
    this.applicants.push(secondary);
  }

}
