import { Component, OnInit } from '@angular/core';
import { EventsManager} from "../../../events/events.manager";

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
showLoading: boolean = false;
  constructor(public em: EventsManager) { }

  ngOnInit(): void {
    this.em.loadingState.subscribe(
      (result:boolean) => {
        this.showLoading = result;
      }
    )
  }

}
