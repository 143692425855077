<div class="header">
  <div class="container">
    <app-error-banner></app-error-banner>
    <div class="row d-none d-sm-flex">
      <div class="col-lg-6">
        <div class="phone-number text-left mt-1">{{office?.telephoneNumber | phone}}</div>
      </div>
      <div class="col-lg-6">
        <div class="admin-by">
          <span class="mt-2 px-4">Administered by</span>
          <img class="logo" src="assets/images/wellfit-plans%201.svg" *ngIf="em.legalLanguage.adminBy == 'wellfit'"/>
          <span *ngIf="em.legalLanguage.adminBy == 'careington'">Careington</span>
        </div>
      </div>
    </div>
    <div class="row d-inline d-sm-none d-md-none d-lg-none d-xl-none">
      <div class="col-xs-12">
        <img class="logo" src="assets/images/small-logo.png"/> <span class="pipe">|</span>
        <span class="phone-number text-left">{{office?.telephoneNumber | phone}}</span>
      </div>
    </div>
  </div>
</div>

