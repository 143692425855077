import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SupportRequestEmail } from 'src/app/models/SupportInformation.model';
import { DataService } from 'src/app/services/data.service';
import { SalesService } from 'src/app/services/sales.service';
import { ValidateBirthdate, ValidateEmail } from '../validators/form-validator';
import {EventsManager} from "../../events/events.manager";

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {
  successMessage : boolean = false;
  plans:any = {};
  currentDateValue:string = ''
  supportRequestForm: FormGroup = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    dob: new FormControl('', [ValidateBirthdate]),
    emailAddress: new FormControl('', [ValidateEmail]),
    question: new FormControl(null),
    message: new FormControl('')
  });

  constructor(public dataservice: DataService, private salesService: SalesService,private em:EventsManager) { }

  ngOnInit(): void {
   this.plans = this.em.getOfficeInfo();
   console.log(this.plans);
  }

  sendSupportEmail(): void {
   let request: SupportRequestEmail = {
     FirstName: this.supportRequestForm.controls['firstName'].value,
     LastName: this.supportRequestForm.controls['lastName'].value,
     EmailAddress: this.supportRequestForm.controls['emailAddress'].value,
     DateOfBirth: this.supportRequestForm.controls['dob'].value,
     Question: this.supportRequestForm.controls['question'].value,
     Message: this.supportRequestForm.controls['message'].value,
   };
    this.salesService.sendSupportEmail(request).subscribe((res)=>{
        this.successMessage = true;
      }
    );
  }

  setFeeScheduleInfo(plan: any){
    let d = {practiceId: this.plans.practiceId, templateId: plan.benefitPlanTemplateId, pageName: "FeeSchedule"}
    sessionStorage['fees'] = JSON.stringify(d);
    this.em.setFeeScheduleInfo(d);
  }

  dateFormat(event:any){
    if(this.currentDateValue.length < 10 && event.target.value.length <= 10 && event.key !== 'Backspace') {
      if (event.target.value.length == 2) {
        event.target.value += '/';
      } else if (event.target.value.length == 5) {
        event.target.value += '/';
      }
      this.currentDateValue = event.target.value;
    } else if(event.key === 'Backspace'){
      this.currentDateValue = event.target.value;
    } else {
      event.target.value = this.currentDateValue;
    }
  }
}
