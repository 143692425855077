<div class="hero">
  <div class="container">
    <div class="row pt-1 pb-1 d-none d-sm-flex">
      <div class="col-lg-5 col-5">
        <div class="main-text ms-4 mt-5">
          Making Your Smile
          Come to Life!
        </div>
        <div class="sub-text ms-4 mt-1">
          Simple easy to understand benefits to meet your dental needs.
        </div>
      </div>
      <div class="col-2">&nbsp;</div>
      <div class="col-lg-5 col-5 mt-4">
        <img src="assets/images/Banner-image2x.png" class="hero-image" alt="hero"/>
      </div>
    </div>
    <div class="row d-lg-none">
      <div class="col-12 ms-5">
        <img src="assets/images/Banner-image1x.png" class="hero-image" alt="hero"/>
      </div>
      <div class="col-12 mt-5 px-5">
        <div class="main-text">
          Making Your Smile
          Come to Life!
        </div>
        <div class="sub-text mt-4">
          Simple easy to understand benefits to meet your dental needs.
        </div>
      </div>
    </div>
  </div>
</div>
