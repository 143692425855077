import { Component, OnInit } from '@angular/core';
import {UtilService} from "../../services/util.service";

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  content:string = '';
  constructor(private utilSrvc:UtilService) { }

  ngOnInit(): void {
    this.utilSrvc.getPrivacyPolicy().subscribe(
      (result) => {
        this.content = result;
      }
    );
  }

}
