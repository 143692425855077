<div class="row mt-5 mb-5">
  <div class="col-12 text-center mt-3 mb-4">
    <img src="assets/images/success-logo.svg" alt="">
  </div>
  <div class="row">
    <div class="col-12 text-center">
      <span class="header">
        Plan Successfully Purchased!
      </span>
    </div>
    <div class="col-12 text-center mb-4">
      <span class="congrats">
        Congratulations on your Dental Plan Purchase!
      </span>
    </div>
    <div class="col-12 small-text text-center">
      Please log into your Member Account, using your email & password selected during account purchase.
      You can download your Membership Card, and access your account information from the Member Portal.
    </div>
  </div>
</div>
