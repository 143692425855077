import {Component, Input, OnInit} from '@angular/core';
import { EventsManager } from "../../events/events.manager";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
 office: any = {};
  constructor(public em:EventsManager) { }

  ngOnInit(): void {
    this.office = this.em.getOfficeInfo();
    //console.log('office: ', this.office);

    this.em.officeUpdate.subscribe(
      (result) => {
        //console.log('off: ', result);
        this.office = result;
      }
    )

  }

}
