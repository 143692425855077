  <div class="row">
    <div class="col-lg-12 card-layout ms-5 mt-5 me-3 mb-3 d-none d-sm-inline">
      <div class="card-text">
        <ng-content></ng-content>
      </div>
    </div>
<!--    <div class="col-lg-12 card-layout mx-1 mt-1 mb-1 d-lg-none">-->
<!--      <div class="card-text">-->
<!--        <ng-content></ng-content>-->
<!--      </div>-->
<!--    </div>-->
  </div>
