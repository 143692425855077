import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {FormControl, FormGroup, ValidationErrors, Validators} from "@angular/forms";
import {EventsManager} from "../../../events/events.manager";
import {MemberModel} from "../../../models/member.model";
import * as moment from 'moment';
import {phoneValidator, ValidateBirthdate} from "../../validators/form-validator";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {SignInComponent} from "../../modals/sign-in/sign-in.component";
import {SalesService} from "../../../services/sales.service";
declare var $:any;

@Component({
  selector: 'app-member-info',
  templateUrl: './member-info.component.html',
  styleUrls: ['./member-info.component.scss']
})
export class MemberInfoComponent implements OnInit, OnDestroy {
  @Input() memberForm!:FormGroup //set to any because I do not want to create a new form group
  @Input() memberNum:any;
  @Input() showDelete:boolean = false;
  @Input() max:any = 0;
  @Input() min:any = 0;
  currentDateValue:string = '';
  isMinor:boolean = false;
  errors:any = [];
  memberData:MemberModel = new MemberModel('','','','','',false);

  constructor(private em: EventsManager, public modalService: NgbModal, private salesService: SalesService) { }

  ngOnInit(): void {

  }

  ngOnDestroy() {
    this.memberForm.removeControl('firstName'+this.memberNum);
    this.memberForm.removeControl('lastName'+this.memberNum);
    this.memberForm.removeControl('email'+this.memberNum);
    this.memberForm.removeControl('dob'+this.memberNum);
    this.memberForm.removeControl('phone'+this.memberNum);
    this.memberForm.removeControl('minor'+this.memberNum);
  }

  setPrimary(member:string) {
    this.em.setWhoIsPrimary(member);
  }

  removeMe() {
    this.em.memberCountUpdate.emit();
  }

  checkForIsMinor(event:any){

    let bday = moment(event.target.value, 'MM/DD/YYYY', true) as moment.Moment;
    this.isMinor = moment().diff(bday, 'years', false) <= 17;
    // console.log('IS MINOR: ', this.isMinor);

    if(this.isMinor){
      this.em.hasMinor.emit();
      //console.log('member is a minor...');
      this.memberForm.get('minor'+this.memberNum)?.setValue('true');
      // @ts-ignore
      if(document.getElementById(this.memberNum)) {
        // @ts-ignore
        document.getElementById(this.memberNum).checked = false;
      }
      this.memberForm.get('email'+this.memberNum)?.removeValidators([Validators.required,Validators.email]);
      this.memberForm.get('phone'+this.memberNum)?.removeValidators([Validators.required,phoneValidator]);
      this.memberForm.removeControl('email'+this.memberNum);
      this.memberForm.removeControl('phone'+this.memberNum);
    } else {
      this.memberForm.get('minor'+this.memberNum)?.setValue('false');
      if(!this.memberForm.contains('email'+this.memberNum)){
        this.memberForm.addControl('email'+this.memberNum, new FormControl('', [Validators.required,Validators.email]));
        this.memberForm.addControl('phone'+this.memberNum, new FormControl('', [Validators.required,phoneValidator]));
      }
    }

  }

  dateFormat(event:any){
    // //console.log(event);
    if(this.currentDateValue.length < 10 && event.target.value.length <= 10 && event.key !== 'Backspace') {
      if (event.target.value.length == 2) {
        event.target.value += '/';
      } else if (event.target.value.length == 5) {
        event.target.value += '/';
      }
      this.currentDateValue = event.target.value;
    } else if(event.key === 'Backspace'){
      this.currentDateValue = event.target.value;
    } else {
      event.target.value = this.currentDateValue;
    }
  }

  translateError(error:string){
    let returnError:string = '';
    switch(error){
      case 'minlength':
        returnError = 'Field is not long enough';
        break;
      case 'invalidPhone':
        returnError = 'Phone Number is not valid';
        break;
      case 'invalidEmail':
        returnError = 'Email is not valid';
        break;
      case 'invalidDate':
        returnError = 'The Date provided is invalid';
        break;
      case 'notAnAdult':
        returnError = 'Member is not an adult';
        break;
      case 'incorrectState':
        returnError = 'State is not a valid State';
        break;
      default:
        returnError = 'Field is not valid';
        break;
    }
    return returnError;
  }

  //leaving as I may use it to help with validations
  checkErrors(label:string=''){
    let totalErrors = 0;
    Object.keys(this.memberForm.controls).forEach(key => {
      // @ts-ignore
      const controlErrors: ValidationErrors = this.memberForm.get(key).errors;
      if (controlErrors != null) {
        totalErrors++;
        Object.keys(controlErrors).forEach(keyError => {
          //console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }

  checkForUser(){
    this.memberData = {
      firstName:this.memberForm.get('firstName'+this.memberNum)?.value,
      lastName: this.memberForm.get('lastName'+this.memberNum)?.value,
      emailAddress: this.memberForm.get('email'+this.memberNum)?.value,
      phoneNumber: this.memberForm.get('phone'+this.memberNum)?.value,
      birthDate: this.memberForm.get('dob'+this.memberNum)?.value,
      isPrimary: false
    }
    this.salesService.getUser(this.memberData.emailAddress)
      .subscribe(
        (result:any) => {
          //console.log('user check: ', result);
          if( result) {
            this.signIn(result);
          }
        })
  }
  signIn(userInfo:any){
    const modalRef: NgbModalRef = this.modalService.open(SignInComponent);
    modalRef.componentInstance.userInfo = userInfo;
    modalRef.result.then(
      (result: any) => {
        this.closeModal();
      },
      error => {
        //console.log('error: ',error);
      }
    )
  }
  closeModal() {
    this.modalService.dismissAll();
  }
}
