import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SalesService } from 'src/app/services/sales.service';
import { phoneValidator } from '../../validators/form-validator';
import { Location } from "@angular/common";
import { environment } from 'src/environments/environment';
import { EventsManager } from 'src/app/events/events.manager';

@Component({
  selector: 'app-invite-a-friend',
  templateUrl: './invite-a-friend.component.html',
  styleUrls: ['./invite-a-friend.component.scss']
})

export class InviteAFriendComponent implements OnInit {
  token: string = '';
  url : string = '';
  showCaptchaError:boolean = false;
  successMessage : boolean = false;
  captcha:string = environment.publicCaptchaKey;
  sendInviteForm: FormGroup = new FormGroup({
    phoneNumber: new FormControl('', [Validators.required,phoneValidator]),
  })
  office: any = {};

  constructor(
    public modal: NgbActiveModal,
    private salesService: SalesService,
    private location : Location,
    private em:EventsManager
  ) {
  }

  ngOnInit(): void {
    this.office = this.em.getOfficeInfo();
    console.log(this.office);
  }

  sendInvitation(): void {
    this.showCaptchaError = false;
    if(this.token !== ''){
      console.log(this.sendInviteForm.valid);
      this.url = this.office == null ? location.origin :  location.origin + "/office/"+ this.office.practiceId;
      let phoneNum = this.sendInviteForm.controls['phoneNumber'].value;
      let captchaRes = this.token;
      this.salesService.sendInvitation(phoneNum, captchaRes, this.url).subscribe((res)=>
        this.successMessage = true
      );
    } else {
      this.showCaptchaError = true;
    }
}

}


