<div class="row">
  <div class="col-12">
    <div class="row">
      <div class="col-11 mt-1">
        <div class="small-header-text">
          {{title}}
        </div>
    </div>
    <div class="col-1 px-0">
      <div class="circle">
        <img id="img{{index}}" src="{{img}}" alt="hideShow" (click)="hideShow(index.toString())"/>
      </div>
    </div>
  </div>
</div>
  <div id="faq{{index}}" class="col-12 sub-text mt-1 hidden">
    {{text}}
  </div>
</div>