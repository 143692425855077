<!-- <div class="sub-header">  -->
  <div class="container">
    <nav class="navbar navbar-expand-lg">
      <div class="container-fluid">
        <span class="pe-0">
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navContent" aria-controls="navContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
        </span>
        <div class="navbar-brand">
          <span class="office-name" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" routerLink="office/{{office.practiceId}}">
            {{office.name}}
          </span>
        </div>
        <div class="spacer">&nbsp;</div>
        <div class="collapse navbar-collapse" id="navContent">
          <ul class="navbar-nav" style="width:100%">
            <li class="nav-item mt-4 ">
              <a class="sub-header-link ms-3" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" routerLink="faq">FAQ'S</a>
            </li>
            <li class="nav-item mt-4">
              <a class="sub-header-link ms-3" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" style="min-width: 75px;" routerLink="support">SUPPORT</a>
            </li>
            <li class="nav-item mt-4 mb-4">
              <a class="sub-header-link ms-3 me-4" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" (click)="inviteAFriend()">INVITE A FRIEND</a>
            </li>
            <li class="nav-item">
              <a class="member-login" href="{{environment.memberPortalLink}}" target="_blank">MEMBER LOGIN</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
<!-- </div> -->
