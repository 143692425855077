<div class="container mb-3">
  <div class="row mt-3 mb-4" *ngIf="!showSuccess">
    <div class="col-12 col-lg-12">
      <app-progress-bar></app-progress-bar>
    </div>
  </div>
  <div class="row" *ngIf="!showSuccess">
    <div class="col-lg-8 col-12 mb-3">
      <app-multi-member *ngIf="step === 1 && !showPaymentScreen && !showPrimary" [level]="level"></app-multi-member>
      <app-single-member *ngIf="step === 2 && !showPaymentScreen || showPrimary" [level]="level"></app-single-member>
      <app-card-info *ngIf="showPaymentScreen"></app-card-info>
    </div>
    <div class="col-lg-4 col-12 mb-3">
      <div class="row ">
        <div class="col-12 card">
          <div class="row">
            <div class="col-12 mb-3">
              <span class="support-header-text">Plan Summary</span>
            </div>
            <div class="col-12 mb-4">
              <label class="col-12 plan-name">{{level.planName}}</label>
            </div>

            <div class="col-6 mb-4">
              <label class="details">Effective Date</label>
            </div>
            <div class="col-6 mb-4 details">
              <label class="">{{today | date: 'MM/dd/yyyy'}}</label>
            </div>

            <div class="col-6 mb-4">
              <label class="details">{{level.caption}}</label></div>
            <div class="col-6 mb-4 details">
              <label class="">${{level.fee}}</label></div>

            <div class="col-6 mb-4">
              <label class="details">Administration Fee</label></div>
            <div class="col-6 mb-4 details">
              <label class="">${{level.adminFee}}</label></div>

            <div class="col-12 mt-3 mb-2">
              <hr>
            </div>
            <div class="col-6 mb-4">
              <label class="details">Total</label></div>
            <div class="col-6 total">${{level.adminFee + level.fee}}</div>
            <div class="col-12 mt-4 text-center">
              <label class="tax-text">*Applicable Taxes May Apply</label>
            </div>
          </div>
        </div>

      </div>
      <div class="row mt-3" *ngIf="showRenewalDetails">
        <div class="col-12 card">
          <div class="row">
            <div class="col-12 renewal-header">
              Automatic Renewal
            </div>
            <div class="col-12 renewal-text">
              Membership is set to automatically renew each year. You may turn off the automatic renewal of your plan by
              visiting the Member Portal or by contacting our Customer Support Team.
            </div>
          </div>
          <div class="row">
            <div class="col-12 renewal-header">
              Cancellation
            </div>
            <div class="col-12 renewal-text">
              This is discount dental plan. This is NOT insurance. You may request a cancellation at any time by contacting our Customer Support Team.
              You will receive a full refund of Membership Fees if a.) you cancel within 30 days of purchase and b.)
              no services have been provided to you under the plan. Restrictions and exceptions may apply. Please review the Membership Agreement for details.
            </div>
          </div>
          <div class="row">
            <div class="col-12 renewal-header">
              Communication
            </div>
            <div class="col-12 renewal-text">
              We will use your E-mail and/or Text as the primary communication for your key Dental plan savings information.
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="row" *ngIf="showSuccess">
    <div class="col-lg-12 col-12 mb-5">
      <app-success></app-success>
    </div>
  </div>
</div>
