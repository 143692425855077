import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import { EventsManager } from "../../events/events.manager";
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-office',
  templateUrl: './office.component.html',
  styleUrls: ['./office.component.scss']
})
export class OfficeComponent implements OnInit{
officeData: any = {};
  constructor(private route: ActivatedRoute, public em: EventsManager, public support: DataService) { }

  ngOnInit(): void {

    this.em.officeUpdate.subscribe(
      (data:any) => {
        this.officeData = this.em.officeInfo;
      }
    );
    if(this.em.officeInfo){
      this.officeData = this.em.officeInfo;
    } else if(sessionStorage['office']){
      this.officeData = JSON.parse(sessionStorage['office']);
    }

  }
}
