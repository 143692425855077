<div class="container">
  <div class="row m-1">
    <div class="col-12 col-lg-12">
      <app-header-text></app-header-text>
      <div class="row card-box mb-5">
        <div class="col-12">
          <div class="row" *ngFor="let faq of faqs;let f = index">
            <div class="col-12 col-lg-12">
              <app-faq-item [title]="faq.faqTitle" [text]="faq.faqText" [index]="f"></app-faq-item>
            </div>
            <div class="row mt-1 mb-1">
              <div class="col-12">
                <hr *ngIf="f != faqs.length-1"/>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
