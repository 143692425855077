import { Component, OnInit } from '@angular/core';
import {SalesService} from "../../services/sales.service";
import {MarketingDataRequest} from "../../models/marketingData.request";
import {EventsManager} from "../../events/events.manager";
import {CommonDetailModel} from "../../models/common-detail.model";

@Component({
  selector: 'app-plan-detail',
  templateUrl: './plan-detail.component.html',
  styleUrls: ['./plan-detail.component.scss']
})
export class PlanDetailComponent implements OnInit {
planInfoDetails: any = {};
officeData:any = {};
details:any = {};
summary:any = [];
footer:string = "X%";
  constructor(private service: SalesService, public em: EventsManager) { }

  ngOnInit(): void {
    window.scrollTo(0,0);
    this.planInfoDetails = this.em.planDetailInfo;
    this.officeData = this.em.officeInfo
    this.getMarketingDetails();
  }

  getMarketingDetails(){
    let params: MarketingDataRequest = {practice: this.planInfoDetails.practiceId, template: this.planInfoDetails.templateId, coverageLevelName: this.em.selectedLevel.caption, pageName: this.planInfoDetails.pageName}

    this.service.getMarketingDetails(params).subscribe(
      (result:any) => {
        this.details = result;
        console.log(result);
        this.details.salesPlanMarketingDetails?.commonDentalPlanSavings?.forEach((item: { itemName: string; value: { column1: string; }[]; }) => {
          if(item.itemName == "Footer") {
            this.footer = item.value[0].column1;
          }
        });

        //console.log(result);

      }
    )
  }
}
