import { Component, OnInit } from '@angular/core';
import {SalesService} from "../../services/sales.service";
import {MarketingDataRequest} from "../../models/marketingData.request";
import {EventsManager} from "../../events/events.manager";
import { Router } from '@angular/router';

@Component({
  selector: 'app-fee-schedule',
  templateUrl: './fee-schedule.component.html',
  styleUrls: ['./fee-schedule.component.scss']
})

export class FeeScheduleComponent implements OnInit {
  plans: any = {};
  feeScheduleInfo: any = {}
  details: any = {};
  selectedPlan: any = {};
  currentCovLevel:number = 0;
  footer:string = "X%";
  constructor(private service: SalesService, private em: EventsManager, private router: Router) { }

  ngOnInit(): void {

    this.plans = this.em.getOfficeInfo();
    this.feeScheduleInfo = this.em.getFeeScheduleInfo();
    console.log('plans: ', this.plans);
    console.log('fees: ', this.feeScheduleInfo);
    this.selectedPlan = this.plans.benefitPlanTemplates.find((x: { benefitPlanTemplateId: any; }) => x.benefitPlanTemplateId == this.feeScheduleInfo.templateId);
    this.getFeeSchedule(this.currentCovLevel);

    // this should fire anytime you update the feeScheduleInfo object
    this.em.feeScheduleUpdate.subscribe(
      (result:any) => {
        this.feeScheduleInfo = result;
      }
    )
  }

  getFeeSchedule(level:number) {
    this.currentCovLevel = level;
    let params: MarketingDataRequest = {practice: this.feeScheduleInfo.practiceId, template: this.feeScheduleInfo.templateId, coverageLevelName: this.selectedPlan.coverageLevels[this.currentCovLevel].caption, pageName: this.feeScheduleInfo.pageName}
    this.service.getMarketingDetails(params).subscribe(
      (result:any) => {
        this.details = result;
        this.details.salesPlanMarketingDetails?.default?.forEach((item: { itemName: string; value: { column1: string; }[]; }) => {
          if(item.itemName == "Footer") {
            this.footer = item.value[0].column1;
          }
        });
      }
    )
  }

  setFeeScheduleInfo(plan: any) {
    let d = {practiceId: this.plans.practiceId, templateId: plan.benefitPlanTemplateId, pageName: "FeeSchedule"}
    this.em.setFeeScheduleInfo(d);
  }
}
