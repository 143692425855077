export const environment = {
  production: true,
  appInsights: {
    instrumentationKey: '[[INSTRUMENTATION_KEY]]',
  },
  recaptcha: {
    siteKey: "[[RECAPTCHA_KEY]]"
  },
  identityUrl: '[[IDENTITYURL]]',
  apiUrl: '[[APIURL]]',
  apiEndpoint: 'treatment/member-portal/',
  treatmentApi: '[[TREATMENTAPI]]',
  pdfApiEndpoint: 'pdfapicore/',
  pdfUrl: '[[PDF]]',
  salesEndpoint: 'sales/',
  paymentsEndpoint: 'payments/',
  blobStorageUrl: '[[BLOB_STORAGE_URL]]',
  siteGraphicUrl: '[[SITE_GRAPHIC_URL]]',
  idleTimeout: 600,
  eProtectScriptUrl: '[[EPROTECTURL]]/eProtect/litle-api2.js',
  AdobeAnalyticsUrl: '[[ADOBEANALYTICSURL]]',
  enableAnalytics: '[[ENABLEANALYTICS]]',
  portalId: '[[PORTAL_ID]]',
  statusCheckSecond: '[[STATUS_CHECK_SECONDS]]',
  memberPortalLink: '[[MEMBER_PORTAL_LINK]]',
  providerPortalLink: '[[PROVIDER_PORTAL_LINK]]',
  salesApiUrl: '[[SALESAPIURL]]',
  eProtectUrl: '[[EPROTECTURL]]',
  payPageId: '[[PAYPAGEID]]',
  reportGroup: '[[REPORTGROUP]]',
  publicCaptchaKey: '[[PUBLIC_CAPTCHA_KEY]]',
};
