<form [formGroup]="multiMemberForm" name="multiMemberForm">
  <div class="row card">
      <div class="row ">
        <div class="col-12 header">
          Plan Member Information
        </div>
        <div class="col-12 sub-header">
          Who will be using this plan?
          <hr>
        </div>
      </div>
      <span *ngFor="let m of currentCount | range; let i = index">
        <app-member-info [memberForm]="multiMemberForm" memberNum="{{i}}" max="{{currentCount-1}}" min="{{level.min}}" [showDelete]="showDeleteBtn" *ngIf="showForm"></app-member-info>
      </span>
    <div class="row" *ngIf="primary === '' && level.min > 1 && minorCount < level.max">
      <div class="col-12 col-1g-12">
        <label class="warning">Primary Member must be selected</label>
      </div>
    </div>
      <div class="row mb-3">
        <div class="col-12 col-lg-12 text-center" *ngIf="(level.max > level.min) && !maxReached">
          <label class="add-member" (click)="addMember()">Add more family members</label>
        </div>
        <div class="col-12">
            <span class="btn-container" style="width: 100%">
<!--              <button class="submit-btn">Continue</button>-->
              <button class="submit-btn" [disabled]="multiMemberForm.invalid" (click)="processForm()">Continue</button>
            </span>
        </div>
      </div>
  </div>
</form>
