<form [formGroup]="primaryForm" name="primaryForm">
<div class="row mb-3 card-box">
  <div class="col-12">
      <div class="row">
        <div class="col-12 header-text">
          Plan Member Billing Information
        </div>
        <div class="col-12 sub-header-text">
          Who is responsible for this plan?
          <hr>
        </div>
        <div class="row">
          <div class="col-lg-6 col-12">
            <label for="firstName" class="label-text">First Name</label>
            <input type="text" class="text-input" name="firstName" id="firstName" formControlName="firstName" required>
            <div *ngIf="primaryForm.get('firstName')?.dirty && primaryForm.get('firstName')?.invalid" class="warning" minlength="3">
              First Name is required
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <label for="lastName" class="label-text">Last Name</label>
            <input type="text" class="text-input" name="lastName" id="lastName" formControlName="lastName" required>
            <div *ngIf="primaryForm.get('lastName')?.dirty && primaryForm.get('lastName')?.invalid" class="warning">
              Last Name is required
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-lg-6 col-12">
            <label for="dob" class="label-text">Date of Birth</label>
            <input type="text" class="text-input" name="dob" id="dob" formControlName="dob" (keyup)="dateFormat($event)" maxlength="10" required>
            <div *ngIf="primaryForm.get('dob')?.dirty && primaryForm.get('dob')?.invalid" class="warning">
              <div class="mt-1" *ngFor="let error of primaryForm.get('dob')?.errors | objToKeys">
                {{ translateError(error) }}
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-12">

          </div>

            <div class="col-12 col-lg-12 mt-3">
              <label for="address1" class="label-text">Address Line 1</label>
              <input type="text" class="text-input" name="address1" id="address1" formControlName="address1" required>
              <div *ngIf="primaryForm.get('address1')?.dirty && primaryForm.get('address1')?.invalid" class="warning">
                Address Line 1 is required
              </div>
            </div>
            <div class="col-12 col-lg-12 mt-3">
              <label for="address2" class="label-text">Address Line 2</label>
              <input type="text" class="text-input" name="address2" id="address2" formControlName="address2">
            </div>
            <div class="col-lg-4 col-12 mt-3">
              <label for="city" class="label-text">City</label>
              <input type="text" class="text-input" name="city" id="city" formControlName="city" required>
              <div *ngIf="primaryForm.get('city')?.dirty && primaryForm.get('city')?.invalid" class="warning">
                City is required
              </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
              <label for="state" class="label-text">State</label>
              <input type="text" class="state-input" name="state" id="state" formControlName="state" maxlength="2" required>
              <div *ngIf="primaryForm.get('state')?.dirty && primaryForm.get('state')?.invalid" class="warning">
                <div class="mt-1" *ngFor="let error of primaryForm.get('state')?.errors | objToKeys">
                  {{ translateError(error) }}
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-12 mt-3">
              <label for="zipCode" class="label-text">Zip Code</label>
              <input type="text" class="text-input" name="zipCode" id="zipCode" minlength="5" maxlength="10" formControlName="zipCode" required>
              <div *ngIf="primaryForm.get('zipCode')?.dirty && primaryForm.get('zipCode')?.invalid" class="warning">
                <div *ngIf="primaryForm.get('zipCode')?.errors">
                  <div class="mt-1" *ngFor="let error of primaryForm.get('zipCode')?.errors | objToKeys">
                    {{translateError(error)}}
                  </div>
                </div>
              </div>
            </div>
          <div class="col-lg-6 col-12 mt-3">
            <label for="email" class="label-text">Email</label>
            <input type="text" class="text-input" name="email" id="email" formControlName="email" required>
            <div *ngIf="primaryForm.get('email')?.dirty && primaryForm.get('email')?.invalid" class="warning">
              <div *ngIf="primaryForm.get('email')?.errors">
                <div class="mt-1" *ngFor="let error of primaryForm.get('email')?.errors | objToKeys">
                  {{translateError(error)}}
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-12 mt-3">
            <label for="phoneNumber" class="label-text">Phone Number</label>
            <input type="text" class="text-input" name="phoneNumber" id="phoneNumber" formControlName="phoneNumber" mask="(000)000-0000" placeholder="(555)555-5555" >
            <div *ngIf="primaryForm.get('phoneNumber')?.invalid && (primaryForm.get('phoneNumber')?.dirty || primaryForm.get('phoneNumber')?.touched)" class="warning">
              <div *ngIf="primaryForm.get('phoneNumber')?.errors">
                <div class="mt-1" *ngFor="let error of primaryForm.get('phoneNumber')?.errors | objToKeys">
                  {{translateError(error)}}
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-12 mt-3">
            <label for="pwd" class="label-text">Password</label>
            <div class="input-group">
              <input [type]=" showPwd ? 'text' : 'password' " class="pwd-input" name="pwd" id="pwd" formControlName="pwd" minlength="8" aria-describedby="basic-addon1" required>
              <span class="input-group-text" id="basic-addon1"><i class="bi" [ngClass]="showPwd ? 'bi-eye-slash-fill' : 'bi-eye-fill'" (click)="showPassword()"></i></span>

            </div>
            <div *ngIf="primaryForm.controls['pwd'].touched && primaryForm.controls['pwd'].invalid"
            class="error-message warning validate-err" style="display: contents;">
            <div class="pw-title">Password must have:</div>
            <ul class="pw-list">
                <li class="pw-item"><span class="pw-item-text">minimum of 8
                        characters</span></li>
                <li class="pw-item"><span class="pw-item-text">at least 1 uppercase
                        letter</span></li>
                <li class="pw-item"><span class="pw-item-text">at least 1 lowercase
                        letter</span></li>
                <li class="pw-item"><span class="pw-item-text">at least 1 number</span></li>
                <li class="pw-item"><span class="pw-item-text">at least 1 special char
                        (e.g. !&#64;#$%^)</span></li>
            </ul>
        </div>
          </div>
          <div class="col-lg-6 col-12 mt-3">
            <label for="pwd2" class="label-text">Confirm Password</label>
            <div class="input-group">
              <input [type]=" showConfPwd ? 'text' : 'password' " class="pwd-input" name="pwd2" id="pwd2" formControlName="pwd2" minlength="8" aria-describedby="confPwd2" required>
              <span class="input-group-text" id="confPwd2"><i class="bi" [ngClass]="showConfPwd ? 'bi-eye-slash-fill' : 'bi-eye-fill'" (click)="showConfirmPassword()"></i> </span>
            </div>
            <div *ngIf="primaryForm.get('pwd2')?.dirty && primaryForm.get('pwd2')?.invalid" class="warning">
              Passwords do not match
            </div>
          </div>

        </div>
        <div class="row mt-4">
          <div class="col-12">
            <span class="btn-container" style="width: 100%">
<!--              <button class="submit-btn">Continue</button>-->
              <button class="submit-btn" [disabled]="!primaryForm.valid" (click)="continue()">Continue</button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
