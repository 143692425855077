<div class="row">
    <div class="col-12 col-sm-12">
      <app-hero></app-hero>
    </div>
</div>
<div class="container">
  <div class="row mb-2">
    <div class="col-12">
      <app-card-item>
        “No waiting, no limits on use and
        everyone is accepted.”
      </app-card-item>
    </div>
  </div>
  <div class="row mb-4">
    <div class="col-12">
      <div class="text-item text-center">Become a Member Today and Start Saving</div>
    </div>
  </div>
  <div *ngFor="let plan of officeData?.benefitPlanTemplates; let i = index">
    <div class="col-12 text-center mt-2 mb-2" *ngIf="officeData?.benefitPlanTemplates[i]?.coverageLevels">
      <span class="plan-name">{{officeData?.benefitPlanTemplates[i]?.name}}</span>
    </div>
    <div class="row mb-4">
      <div class="col-12">
        <div class="row">
          <div class="col-lg-6 col-xs-12 mb-4" *ngFor="let level of officeData?.benefitPlanTemplates[i]?.coverageLevels">
            <app-plan-card [planName]="officeData?.benefitPlanTemplates[i]?.name" [practiceId]="officeData?.practiceId" [templateId]="officeData?.benefitPlanTemplates[i]?.benefitPlanTemplateId" [cardData]="level"></app-plan-card>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  <div class="row">
    <div class="col-12" style="height: 25px">&nbsp;</div>
  </div>

<app-legal-text [officeData]="officeData"></app-legal-text>
