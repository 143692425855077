import {Component, Input, OnInit} from '@angular/core';
import { NgbCollapseModule,NgbCollapse } from '@ng-bootstrap/ng-bootstrap';
import { min } from 'underscore';
@Component({
  selector: 'app-faq-item',
  templateUrl: './faq-item.component.html',
  styleUrls: ['./faq-item.component.scss'],
})
export class FaqItemComponent implements OnInit {
@Input() title: string = '';
@Input() text: string = '';
@Input() index: number = 0;

plus:string = 'assets/images/PlusCircle.png';
minus:string = 'assets/images/MinusCircle.png';
img:string = this.plus;
  constructor() { }

  ngOnInit(): void {
  }

  hideShow(item:string){
    // @ts-ignore
   if(document.getElementById('faq'+item).classList.contains('hidden')){
     // @ts-ignore
     document.getElementById('faq'+item).classList.remove('hidden');
     document.getElementById('img'+item)?.setAttribute('src',this.minus);
   } else {
     // @ts-ignore
     document.getElementById('faq'+item).classList.add('hidden');
     document.getElementById('img'+item)?.setAttribute('src',this.plus);
   }

  }
}
