import {Component, Input, OnInit} from '@angular/core';
import {EventsManager} from "../../events/events.manager";
import {DataService} from "../../services/data.service";

@Component({
  selector: 'app-legal-text',
  templateUrl: './legal-text.component.html',
  styleUrls: ['./legal-text.component.scss']
})
export class LegalTextComponent implements OnInit {
  @Input() officeData: any = {};
  constructor(public em: EventsManager, public support: DataService) { }

  ngOnInit(): void {

  }


  get templateName() : string {
    if (this.officeData && this.officeData.benefitPlanTemplates && this.officeData.benefitPlanTemplates.length > 0) {
      return this.officeData?.benefitPlanTemplates[0]?.name;
    }
    return '';
  }

}
