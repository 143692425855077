import {Component, Input, OnInit} from '@angular/core';
import {PrimaryModel} from "../../../models/primary.model";
import {AbstractControl, FormControl, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {phoneValidator, ValidateBirthdate, ValidateEmail, zipValidator, isAMinor} from "../../validators/form-validator";
import {EventsManager} from "../../../events/events.manager";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {SignInComponent} from "../../modals/sign-in/sign-in.component";
import {SalesService} from 'src/app/services/sales.service';
import { DatePipe } from '@angular/common';
import {stateValidator} from "../../validators/state.validator";
import * as moment from "moment/moment";

@Component({
  selector: 'app-single-member',
  templateUrl: './single-member.component.html',
  styleUrls: ['./single-member.component.scss']
})
export class SingleMemberComponent implements OnInit {
@Input() level:any = {};
showPwd:boolean = false;
showConfPwd:boolean = false;
selectedLevel:any = {};

memberInfo: PrimaryModel = new PrimaryModel('', '', '', '', '', '', '', '', '', '', '', true);
primaryForm:FormGroup = new FormGroup(
  {firstName: new FormControl('', [Validators.required,Validators.minLength(2)]),
    lastName: new FormControl('', [Validators.required,Validators.minLength(2)]),
    dob: new FormControl('', [Validators.required,ValidateBirthdate,Validators.maxLength(10),isAMinor]),
    address1: new FormControl('', [Validators.required,Validators.minLength(4)]),
    address2: new FormControl(''),
    city: new FormControl('', [Validators.required]),
    state: new FormControl('', [stateValidator(),Validators.required, Validators.minLength(2),Validators.maxLength(2)]),
    zipCode: new FormControl('', [zipValidator,Validators.required]),
    email: new FormControl('', [Validators.required, ValidateEmail]),
    pwd: new FormControl('', [Validators.required, Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,}$'), Validators.minLength(8)]),
    pwd2: new FormControl('',[Validators.required, this.matchValues('pwd')]),
    phoneNumber: new FormControl('', [Validators.required,Validators.minLength(10),Validators.maxLength(13),phoneValidator]),

})

userInfo: any = {};
currentPurchase:any = {};
currentDateValue: string = '';
  constructor(private em: EventsManager, public modalService: NgbModal, private salesService: SalesService, private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.selectedLevel = this.em.getSelectedLevel();
    //console.log('sel level: ', this.selectedLevel);
    this.salesService.getPurchase(false).subscribe(
      (result:any) => {
        //console.log('purchase: ', result);
        if(!result.primaryApplicant || !result.responsibleParty){
          //console.log(' no purchase info');
          this.checkForApplicantData();
        } else {
          this.em.purchaseInfo = result;
          this.currentPurchase = result;
          this.checkForPurchaseInfo();
        }

      }
    );


    this.primaryForm.controls['pwd'].valueChanges.subscribe({
      next: () => this.primaryForm.controls['pwd2'].updateValueAndValidity()
    })
  }

  showPassword(){
    this.showPwd = !this.showPwd;
  }

  showConfirmPassword(){
    this.showConfPwd = !this.showConfPwd;
  }

  matchValues(matchToo: string):ValidatorFn {
    return (control: AbstractControl)=> {
      return control.value === control.parent?.get(matchToo)?.value ? null : {notMatching: true}
    }
  }
  dateFormat(event:any){
    if(this.currentDateValue.length < 10 && event.target.value.length <= 10 && event.key !== 'Backspace') {
      if (event.target.value.length == 2) {
        event.target.value += '/';
      } else if (event.target.value.length == 5) {
        event.target.value += '/';
      }
      this.currentDateValue = event.target.value;
    } else if(event.key === 'Backspace'){
      this.currentDateValue = event.target.value;
    } else {
      event.target.value = this.currentDateValue;
    }
  }
  saveMemberInfo(){
    this.em.togglePrimary.emit(false);
    let applicants:any = {};
    if(this.isPrimaryAMinor() && (this.selectedLevel.min == 1 && this.selectedLevel.max == 1)){
     // //console.log('is a Minor and is an individual plan');
      applicants.primaryApplicant = this.em.applicants.primaryApplicant;
    } else if(this.em.minorCount == this.selectedLevel.max) {
     // //console.log('all members are minors');
      applicants.primaryApplicant = this.em.applicants.secondary[0];
      this.em.applicants.secondary.shift();
    } else {
     // //console.log('the primary is the same as the responsible party');
      applicants.primaryApplicant = this.memberInfo;
    }

    // if the primary or any of the secondary are minors
    if(this.isPrimaryAMinor() || this.isAMinorPresent()){
      applicants.responsibleParty = this.memberInfo;
    }

    if(this.em.applicants.secondary?.length > 0){
      applicants.associatedApplicants = this.em.applicants.secondary;
    } else {
      applicants.associatedApplicants = [];
    }

    this.em.setApplicants(applicants);
    this.salesService.addApplicants(applicants).subscribe(
      (result:any) =>{
        this.em.sendShowPaymentScreen(true);
        this.em.setPurchaseStep.emit(3);
      }
    )

  }

  checkForApplicantData(){
    if(this.em.applicants !== null){
        //console.log('is minor: ',this.isPrimaryAMinor());
        if(!this.isPrimaryAMinor()){
          //console.log('we SHOULD NOT BE HERE!!!!');
          this.memberInfo.firstName = this.em.applicants.primaryApplicant?.firstName;
          this.memberInfo.lastName = this.em.applicants.primaryApplicant?.lastName;
          this.memberInfo.emailAddress = this.em.applicants.primaryApplicant?.email;
          this.memberInfo.birthDate = this.datePipe.transform(this.em.applicants.primaryApplicant?.birthDate,'MM/dd/yyyy');
          this.memberInfo.phoneNumber = this.em.applicants.primaryApplicant?.phoneNumber;
          this.primaryForm.get('firstName')?.setValue(this.memberInfo.firstName);
          this.primaryForm.get('lastName')?.setValue(this.memberInfo.lastName);
          this.primaryForm.get('dob')?.setValue(this.memberInfo.birthDate);
          this.primaryForm.get('email')?.setValue(this.memberInfo.emailAddress);
          this.primaryForm.get('phoneNumber')?.setValue(this.memberInfo.phoneNumber);
        } else {
          //console.log('we SHOULD BE HERE!!!!');
          if(this.em.applicants.responsibleParty){
            //console.log('we SHOULD AND HERE!!!!');
            this.primaryForm.get('firstName')?.setValue(this.em.applicants.responsibleParty.firstName);
            this.primaryForm.get('lastName')?.setValue(this.em.applicants.responsibleParty.lastName);
            this.primaryForm.get('dob')?.setValue(this.em.applicants.responsibleParty.birthDate);
            this.primaryForm.get('email')?.setValue(this.em.applicants.responsibleParty.emailAddress);
            this.primaryForm.get('phoneNumber')?.setValue(this.em.applicants.responsibleParty.phoneNumber);
          }
        }

      }
  }
  checkForPurchaseInfo(){
    if(this.currentPurchase?.primaryApplicant && !this.isPrimaryAMinor()){
      this.memberInfo.address = this.currentPurchase.primaryApplicant.address;
      this.memberInfo.birthDate = this.datePipe.transform(this.currentPurchase.primaryApplicant.birthDate,'MM/dd/yyyy');
      this.memberInfo.city = this.currentPurchase.primaryApplicant.city;
      this.memberInfo.emailAddress = this.currentPurchase.primaryApplicant.emailAddress;
      this.memberInfo.firstName = this.currentPurchase.primaryApplicant.firstName;
      this.memberInfo.lastName = this.currentPurchase.primaryApplicant.lastName;
      this.memberInfo.phoneNumber = this.currentPurchase.primaryApplicant.phoneNumber;
      this.memberInfo.state = this.currentPurchase.primaryApplicant.state;
      this.memberInfo.zipCode = this.currentPurchase.primaryApplicant.zipCode;
      this.primaryForm.get('firstName')?.setValue(this.memberInfo.firstName);
      this.primaryForm.get('lastName')?.setValue(this.memberInfo.lastName);
      this.primaryForm.get('dob')?.setValue(this.memberInfo.birthDate);
      this.primaryForm.get('address1')?.setValue(this.memberInfo.address);
      this.primaryForm.get('city')?.setValue(this.memberInfo.city);
      this.primaryForm.get('state')?.setValue(this.memberInfo.state);
      this.primaryForm.get('zipCode')?.setValue(this.memberInfo.zipCode);
      this.primaryForm.get('email')?.setValue(this.memberInfo.emailAddress);
      this.primaryForm.get('phoneNumber')?.setValue(this.memberInfo.phoneNumber);
      this.em.setPrimaryApplicant(this.memberInfo);
    } else if(this.isPrimaryAMinor()){
      this.primaryForm.get('firstName')?.setValue(this.currentPurchase.responsibleParty?.firstName);
      this.primaryForm.get('lastName')?.setValue(this.currentPurchase.responsibleParty?.lastName);
      this.primaryForm.get('dob')?.setValue(this.currentPurchase.responsibleParty?.birthDate);
      this.primaryForm.get('address1')?.setValue(this.currentPurchase.responsibleParty?.address);
      this.primaryForm.get('city')?.setValue(this.currentPurchase.responsibleParty?.city);
      this.primaryForm.get('state')?.setValue(this.currentPurchase.responsibleParty?.state);
      this.primaryForm.get('zipCode')?.setValue(this.currentPurchase.responsibleParty?.zipCode);
      this.primaryForm.get('email')?.setValue(this.currentPurchase.responsibleParty?.emailAddress);
      this.primaryForm.get('phoneNumber')?.setValue(this.currentPurchase.responsibleParty?.phoneNumber);
    }
  }

  continue() {
    this.salesService.getUser(this.primaryForm.controls['email'].value)
    .subscribe(
      (result:any) => {
        this.userInfo = result;
        if( this.userInfo == undefined || this.userInfo == '') {
            this.memberInfo.firstName = this.primaryForm.get('firstName')?.value;
            this.memberInfo.lastName = this.primaryForm.get('lastName')?.value;
            this.memberInfo.birthDate = this.primaryForm.get('dob')?.value;
            this.memberInfo.address = this.primaryForm.get('address1')?.value;
            this.memberInfo.addressline1 = this.primaryForm.get('address1')?.value;
            this.memberInfo.city = this.primaryForm.get('city')?.value;
            this.memberInfo.state = this.primaryForm.get('state')?.value;
            this.memberInfo.zipCode = this.primaryForm.get('zipCode')?.value;
            this.memberInfo.emailAddress = this.primaryForm.get('email')?.value;
            this.memberInfo.phoneNumber = this.primaryForm.get('phoneNumber')?.value;
            this.memberInfo.password = this.primaryForm.get('pwd')?.value;
            this.em.primaryMember = this.memberInfo;
            this.saveMemberInfo();
        }
        else {
          this.signIn();
          return;
        }
      }
    );
  }

  signIn(){
    const modalRef: NgbModalRef = this.modalService.open(SignInComponent);
    modalRef.componentInstance.userInfo = this.userInfo;
    modalRef.result.then(
      (result: any) => {
        this.closeModal();
      },
      error => {
        //console.log('error: ',error);
      }
    )
  }

  translateError(error:string){
    let returnError:string = '';
    console.log('error: ', error);
    switch(error){
      case 'minlength':
        returnError = 'Field is not long enough';
        break;
      case 'invalidPhone':
        returnError = 'Phone Number is not valid';
        break;
      case 'invalidEmail':
        returnError = 'Email is not valid';
        break;
      case 'invalidDate':
        returnError = 'The Date provided is invalid';
        break;
      case 'notAnAdult':
        returnError = 'The responsible party cannot be a minor';
        break;
      case 'incorrectState':
        returnError = 'State is not a valid State';
        break;
      case 'invalidZip':
          returnError = 'ZipCode is not valid';
          break;
      case 'required':
          returnError = 'Field is required';
          break;
      default:
        returnError = 'Field is not valid';
        break;
    }
    return returnError;
  }

  isPrimaryAMinor():boolean{
    //console.log('bday: ',this.em.applicants.primaryApplicant?.birthDate);
    let bday = moment(this.em.applicants.primaryApplicant?.birthDate, 'MM/DD/YYYY', true) as moment.Moment;
    return moment().diff(bday, 'years', false) <= 17;
  }

  amIAMinor(myBday:string):boolean{
    //console.log('bday: ',this.em.applicants.primaryApplicant?.birthDate);
    let bday = moment(myBday, 'MM/DD/YYYY', true) as moment.Moment;
    return moment().diff(bday, 'years', false) <= 17;
  }

  isAMinorPresent():boolean {
    let isPresent = false;
    for(let i:number = 0;i < this.em.applicants.secondary.length;i++){
      let bday = moment(this.em.applicants.secondary.birthDate, 'MM/DD/YYYY', true) as moment.Moment;
      let found:boolean = moment().diff(bday, 'years', false) <= 17;
      if(found){
        isPresent = true;
        break;
      }
    }

    return isPresent;
  }

  closeModal() {
    this.modalService.dismissAll();
  }
}
