import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {HomeComponent} from "./components/home/home.component";
import {OfficeComponent} from "./components/office/office.component";
import {PlanDetailComponent} from "./components/plan-detail/plan-detail.component";
import {FaqComponent} from "./components/faq/faq.component";
import {SupportComponent} from "./components/support/support.component";
import {PurchaseComponent} from "./components/purchase/purchase.component";
import {FeeScheduleComponent} from "./components/fee-schedule/fee-schedule.component";
import {PrivacyPolicyComponent} from "./components/privacy-policy/privacy-policy.component";
import {TermsOfUseComponent} from "./components/terms-of-use/terms-of-use.component";

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'office', component: OfficeComponent,},
  { path: 'office/:id', component: OfficeComponent},
  { path: 'plan', component: PlanDetailComponent,},
  { path: 'plan/:id', component: PlanDetailComponent},
  { path: 'purchase', component: PurchaseComponent},
  { path: 'faq', component: FaqComponent},
  { path: 'support', component: SupportComponent},
  { path: 'feeSchedule', component: FeeScheduleComponent},
  { path: 'privacy', component: PrivacyPolicyComponent},
  { path: 'terms-of-use', component: TermsOfUseComponent},
  { path: ':orgvanity/:pvanity', component: OfficeComponent},
  { path: ':pvanity', component: OfficeComponent},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
