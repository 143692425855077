export class PrimaryModel{
  constructor(
    public firstName: string,
    public lastName: string,
    public birthDate: string | null,
    public address: string,
    public addressline1: string,
    public city: string,
    public state: string,
    public zipCode: string,
    public phoneNumber: string,
    public emailAddress: string,
    public password: string,
    isResponsibleParty: boolean
  ) {
  }

}
