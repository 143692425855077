<div class="card">
  <div class="row">
    <div class="col-12">
      <div class="coverage-level text-center mt-3 mb-3">{{cardData.name}}</div>
      <div class="text-center">
        <span class="dollar-sign">$</span>
        <span class="level-price mb-3">{{(cardData.fee + cardData.adminFee)}}</span>
        <br>
        <span class="duration mt-2 mb-1">
          for 12 months
            <br>
          * Price includes a one-time, nonrefundable processing fee of $15.00
        </span>
      </div>
    </div>
  </div>
  <div class="row mt-3 mb-2">
    <div class="col-4 p-0"></div>
    <div class="col-4 p-0">
      <div class="join-now" (click)="setCoverageLevel(cardData)" routerLink="/purchase">Select</div>
    </div>
    <div class="col-4 p-0"></div>
    <div class="col-12 mt-4 mb-4 text-center">
      <a href="" class="benefit-link" (click)="setPlanDetails(cardData)" routerLink="/plan">Click here for Benefit Details</a>
    </div>
    <div class="col-12 mb-4 text-center">
      <label class="tax-text">*Applicable Taxes May Apply</label>
    </div>
  </div>
</div>
