<div class="modal fade show d-block modal-wf-lg">
  <div class="modal-dialog modal-dialog">
    <div class="modal-content modal-container">
      <div class="modal-body">
        <div class="row header-layout mb-3">
          <div class="col-12 header text-center">
            Member Agreement
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12 text-center plan-name">
            {{planName}} DENTAL PLAN
            MEMBERSHIP AGREEMENT
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-12">
            <iframe width="100%" height="350" [src]="urlSafe" type="application/pdf"></iframe>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="col-6">
            <button class="cancel-btn" (click)="decline()">Cancel</button>
          </div>
          <div class="col-6">
            <button class="accept-btn" (click)="accept()">Accept</button>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
