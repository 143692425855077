import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class SessionInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    //kind of a hack because of how the setHeader wants the datatype
    let session:any = '';
    if(sessionStorage.getItem('session')){
      session = sessionStorage.getItem('session')
    } else {
      sessionStorage.setItem('session',this.guid());
      session = sessionStorage.getItem('session');
    }
    request =  request.clone({
      setHeaders: {
        'session': session
      }
    })
    return next.handle(request);
  }
  guid() {
    console.log('setting guid')
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
      s4() + '-' + s4() + s4() + s4();
  }
}
