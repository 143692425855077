import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

/**
 * The validator that checks to see if the state is valid.
 *
 * @export
 * @param {AbstractControl} control
 * @returns {{ [key: string]: any }}
 */
export function stateValidator(): ValidatorFn  {
  return (control:AbstractControl) : ValidationErrors | null => {
    if (!control.value) {
      return null;
    }
    const states = ['AL','AK','AZ','AR','CA','CO','CT','DE','FL','GA','HI','ID','IL','IN','IA','KS','KY','LA','ME','MD','MA','MI','MN','MS','MO','MT','NE','NV','NH','NJ','NM','NY','NC','ND','OH','OK','OR','PA','RI','SC','SD','TN','TX','UT','VT','VA','WA','WV','WI','WY'];
    let invalidState: boolean = true;
    for(let s:number = 0; s < states.length; s++){
      if(states[s] === control.value.toUpperCase()){
        invalidState = false;
        break;
      }
    }

    return invalidState ? {incorrectState:true}: null;

  }
}


