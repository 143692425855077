<div class="legal-body">
  <div class="container">
    <div class="row mt-2" [ngClass]="em.legalLanguage.adminBy === 'wellfit' ? 'mb-2' : ''">
      <div class="col-12 legal-text" >
      <span class="legal-text-bold">
        <span *ngIf="em.legalLanguage.adminBy == 'wellfit'">{{ templateName }}</span>
        {{em.legalLanguage.prefix}}
      </span>
        <span [innerHTML]="em.legalLanguage.text"> </span>
        <span *ngIf="em.legalLanguage.adminBy == 'wellfit'">&nbsp;{{support.PhoneNumber}}</span>
      </div>
    </div>
    <div class="row mt-5 mb-5" *ngIf="em.legalLanguage.adminBy === 'careington'">
      <div class="col-12 small-legal-text">
        *Includes a one-time, non-refundable $15 application fee. (If cancelled, the application fee is refundable to residents of AR, FL and LA. MD residents receive a $10 refund of the application fee).
      </div>
    </div>
  </div>
</div>
