import { Injectable } from '@angular/core';
import { SupportInformation } from '../models/SupportInformation.model';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  PhoneNumber: string = '';
  EmailAddress: string= '';
  MailingAddress: string= '';
  WeekdayHours: string= '';
  WeekendHours: string= '';

  constructor() { }

  setSupportInfo(supportInfo: SupportInformation)
  {
    this.PhoneNumber = supportInfo.PhoneNumber;
    this.EmailAddress = supportInfo.EmailAddress;
    this.MailingAddress = supportInfo.MailingAddress;
    this.WeekdayHours = supportInfo.WeekdayHours;
    this.WeekendHours = supportInfo.WeekendHours;
  }
}
