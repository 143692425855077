import {Component, Input, OnInit} from '@angular/core';
import {EventsManager} from "../../../events/events.manager";
import {SalesService} from "../../../services/sales.service";

@Component({
  selector: 'app-plan-card',
  templateUrl: './plan-card.component.html',
  styleUrls: ['./plan-card.component.scss']
})
export class PlanCardComponent implements OnInit {
@Input() cardData:any = {};
@Input() templateId:string = '';
@Input() practiceId:string = '';
@Input() planName:string = '';
pageName:string = 'PlanDetails';
  constructor(private em:EventsManager, private salesSrvc: SalesService) { }

  ngOnInit(): void {

  }

  setCoverageLevel(level:any){

    level.planName = this.planName;
    this.em.setLevelSelected(level);
    this.salesSrvc.selectPlan(level.benefitPlanCoverageLevelId).subscribe(
      (result) => {
        console.log('plan: ', result);
      }, (error) => {
        console.log(error);
    }
    )
  }

  setPlanDetails(level:any){
    this.setCoverageLevel(level);
    let d = {practiceId: this.practiceId, templateId: this.templateId, pageName: this.pageName}
    this.em.setPlanDetails(d);
  }
}
