import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {NgbActiveModal, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {PlansService} from "../../services/plans.service"
import * as _ from 'underscore';
import {GeoLookupModel} from "../../models/geo-lookup.model";
import {EventsManager} from "../../events/events.manager";
@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  constructor(public modal: NgbActiveModal, private service: PlansService,private em:EventsManager) { }
  zip:string = '';
  distance:number = 0;
  state: string = 'Texas';
  showPlans: boolean = false;
  showNoEntries: boolean = false;
 offices: any ;
  ngOnInit(): void {

  }

  getOffices(){
    this.offices = {};
    this.showPlans = false;
    console.log('zip: ', this.zip);
    console.log('dist: ', this.distance);
    const geoObj: GeoLookupModel = {
      zipCode : this.zip,
      dist : this.distance,
      org: this.em.orgId
    }
    if(this.distance !== 0){
      this.service.getOffices(geoObj).subscribe(
        (response) =>{
          if(response.entries){
            this.offices = response.entries;
            this.showPlans = true;
            this.showNoEntries = false;
          } else {
            this.showNoEntries = true;
          }

        },
        (error) => {
          console.log(error);
        }
      )
    }

  }
  close(office:string){
    console.log('office: ', office);
    this.modal.close(office);
  }

  clear(){
    this.zip = '';
    this.distance = 0;
    this.showPlans = false;
    this.showNoEntries = false;
  }
}
