import {Component, Inject, OnInit} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import {EventsManager} from "../../../events/events.manager";
import {PlansService} from "../../../services/plans.service";
import {GetDocumentDetailRequest, GetMembershipAgreeementRequest} from "../../../models/document.request";

@Component({
  selector: 'app-member-agreement',
  templateUrl: './member-agreement.component.html',
  styleUrls: ['./member-agreement.component.scss']
})
export class MemberAgreementComponent implements OnInit {
allowClose:boolean = false;
urlSafe: SafeResourceUrl = '';
memberAgreementUrl:string = '';
planName:string = '';

  constructor(public modal: NgbActiveModal,private sanitizer: DomSanitizer,private em:EventsManager, private planService:PlansService) { }

  ngOnInit(): void {
    this.planName = this.em.selectedLevel.planName;
    let request: GetMembershipAgreeementRequest = {
      practiceName: this.em.officeInfo?.practice.name ?? '',
      planName: this.em.selectedLevel.planName
    }

    // call web service to generate document
    this.planService.getMembershipAgreement(request).subscribe((response: Blob) => {
      const url  = window.URL.createObjectURL(response);
      const viewerUrl = `${url}#toolbar=0&zoom=FitW&toolbar=0&navpanes=0`;
      this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(viewerUrl);
    })

  }

  accept(){
      this.em.acceptMemberAgreement.emit(true);
      this.closeMe();
  }

  decline(){
    this.em.acceptMemberAgreement.emit(false);
    this.closeMe();
  }

  closeMe(){
    this.modal.close()
  }
}
