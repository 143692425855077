import { Component, OnInit } from '@angular/core';
import {EventsManager} from "../../../events/events.manager";

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {
currentStep: number = 2;
  constructor(private em: EventsManager) { }

  ngOnInit(): void {
    this.em.setPurchaseStep.subscribe(
      (result:number) => {
        this.currentStep = result;
      }
    )
  }
}
