import { Component, OnInit } from '@angular/core';
import { UtilService } from "../../services/util.service";
import { DataService } from 'src/app/services/data.service';
import { forEach } from 'underscore';


@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  faqs: any;
  phoneNumber : string = '';
  weekdaysupport: string = '';
  response : any;
  

  constructor(private service: UtilService,
              private dataService: DataService) {
    
  }

  ngOnInit(): void {
    //set the support hours and phone Number
    this.phoneNumber = this.dataService.PhoneNumber;
    this.weekdaysupport = this.dataService.WeekdayHours
    this.getFaqs();
  }

  getFaqs() {
    this.service.getFaqs().subscribe(
      (response) => {
        //replace values in list of faq's       
       this.replaceSupportInformation(response);
      },
      (error) => {
        console.log(error);
      }
    )
  }

  replaceSupportInformation(response: any){
      var replaceJson = [
      {
        "targetText" : "{phoneNumber}",
        "value" :this.phoneNumber
        },
        {
          "targetText" : "{supportHours}",
          "value" :this.weekdaysupport
        },
      ];
      
      for (let index = 0; index < replaceJson.length; index++) {
        const element = replaceJson[index];
        response = replaceTextWithDynamicValue(response, element.targetText, element.value);
      }
      this.faqs = response;
  }

  
}

function replaceTextWithDynamicValue(obj: any, targetText: string, dynamicValue: any): any {
  if (typeof obj === 'object' && obj !== null) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        obj[key] = replaceTextWithDynamicValue(obj[key], targetText, dynamicValue);
      }
    }
  } else if (typeof obj === 'string') {
    // Replace the target text with the dynamic value
    obj = obj.replace(new RegExp(targetText, 'g'), dynamicValue);
  }

  return obj;
}
