import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {catchError, map, Observable, of, throwError} from "rxjs";
import { environment } from 'src/environments/environment';
import { sendInvitationRequest } from '../models/send-invite.model';
import {MarketingDataRequest} from "../models/marketingData.request";
import { SupportRequestEmail } from '../models/SupportInformation.model';
import {resolve} from "@angular/compiler-cli";
import {EventsManager} from "../events/events.manager";
import { PortalPurchaseSummary } from '../models/sales.model';
declare var LitlePayPage: any;

@Injectable({
  providedIn: 'root'
})
export class SalesService {
  litleInvalidCardCodes:any = [];
  invalidCardMessage:string = '';
  errorMessageToBeUsedWhenNotAnInvalidCard:string = '';
  constructor(private http: HttpClient, public em: EventsManager) {
   }

   options: HttpHeaders = new HttpHeaders({

  })

   getMarketingDetails(plan:MarketingDataRequest):Observable<any> {
      const url: string = `${environment.apiUrl}sales/purchase/portal/get-main-page-detail-for-plan`;
      return this.http.get(url+'?PracticeId='+plan.practice+'&BenefitPlanTemplateId='+plan.template+'&CoverageLevelName='+plan.coverageLevelName+'&PageName='+plan.pageName).pipe(
        catchError((e: any) => {
          return e;
        })
      )
   }

   /*send invite to friend with the site details and to mentioned phone number*/
   sendInvitation(phoneNumber : string, captchaResponse : string, site : string):Observable<any> {
     console.log('send invite');
    const url = `${environment.salesApiUrl}services/send-mysmile-invite`;
    const request: sendInvitationRequest = {
      phoneNumber: phoneNumber,
      captchaResponse: captchaResponse,
      site : site
    };
    return this.http.post<void>(url, request).pipe(
        catchError((e: any) => {
            return e;
        }),
    );
  }

  /*send support email to support team*/
  sendSupportEmail(request: SupportRequestEmail):Observable<any>{
    const url = `${environment.salesApiUrl}purchase/portal/send-support-request-email`;
    return this.http.post<void>(url, request).pipe(
        catchError((e: any) => {
            return e;
        }),
    );
  }

  getUser(userName: string): any {
    //this.userName = userName;
    const url = `${environment.salesApiUrl}purchase/portal/get-user`;
    return this.http.post<any>(url, {userName: userName})
    .pipe(
      catchError((e: any) => {
          return e;
      }),
    )
  }

  generateOTP(request: any): any {
    const url = `${environment.salesApiUrl}purchase/portal/generate-otp`;
    return this.http.post<any>(url, request)
    .pipe(
      catchError((e: any) => {
          return e;
      }),
    );
  }

  authenticateOTP(request: any): any {
    const url = `${environment.salesApiUrl}purchase/portal/authenticate-otp`;
    return this.http.post<any>(url, request)
    .pipe(
      catchError((e: any) => {
          return e;
      }),
    );
  }

  signIn(request: any): any {
    const url = `${environment.salesApiUrl}purchase/portal/sign-in`;
    return this.http.post<any>(url, request)
    .pipe(
      catchError((e: any) => {
          return e;
      }),
    );
  }

  sendPasswordResetEmail(request: any): Observable<any> {
    const url = `${environment.salesApiUrl}purchase/portal/send-password-reset-email`;
    return this.http.post<void>(url, request).pipe(
        catchError((e: any) => {
            return e;
        }),
    );
  }

  sendActivationEmail(request: any) {
    const url = `${environment.salesApiUrl}purchase/portal/send-activation-email`;
    return this.http.post<void>(url, request).pipe(
        catchError((e: any) => {
            return e;
        }),
    );
  }

  selectPlan(plan:any): Observable<any>{
    const url: string = `${environment.salesApiUrl}purchase/portal/select-plan`;
    return this.http.post(url,{coverageLevelId: plan, source: 'MsdpPurchasePortal'}).pipe(
      catchError((e: any) => {
        return e;
      }),
    )
  }

  getPurchase(status:boolean):Observable<PortalPurchaseSummary>{
    let url = `${environment.salesApiUrl}purchase/portal/get-purchase-summary?complete=${status}`;
    return this.http.get<PortalPurchaseSummary>(url).pipe(
      catchError((e:any) => {
        return throwError(() => new Error(e.message || 'Server error'));
      })
    )
  }

  addApplicants(data:any):Observable<any>{
    const url:string = `${environment.salesApiUrl}purchase/portal/add-applicants`;
    return this.http.post(url, data).pipe(
      catchError((e:any) => {
        return of(e);
      })
    )
  }

  getCreditCardType(cardNumber:string) {
    return (/^5[1-5]/.test(cardNumber)) ? "mastercard"
      : (/^4/.test(cardNumber)) ? "visa"
        : (/^3[47]/.test(cardNumber)) ? 'amex'
          : (/^6011|65|64[4-9]|622(1(2[6-9]|[3-9]\d)|[2-8]\d{2}|9([01]\d|2[0-5]))/.test(cardNumber)) ? 'discover'
            : '';
  }

  completePurchase(info:any,cardInfo:any):Observable<any> {
    console.log('info in srvc: ', info);
    var endPointUrl = environment.salesApiUrl + 'purchaseportal/completepurchaseplan';
    return this.http.post(endPointUrl, { accountNumber: cardInfo.lastFour, creditCardInfo: cardInfo, isPlanAutoRenewal:info.isPlanAutoRenewal, shouldNotifyByMail:info.shouldNotifyByMail,practiceId: info.practiceId, isMedicalConfirmed : info.isMedicalConfirmed }).pipe(
      catchError((e:any) => {
        return of(e);
      })
    );
  }

  tokenizePaymentMethod(account:any,cvv:any):Observable<any>{
    const settings:any = {
      payPageId: environment.payPageId,
      reportGroup: environment.reportGroup,
      eProtectUrl: environment.eProtectUrl
    }

    let id:any = this.generateOrderId();

    let litleRequest:any = {
      "paypageId": settings.payPageId,
      "reportGroup": settings.reportGroup,
      "orderId": id,
      "id": id,
      "url": settings.eProtectUrl
    };

    let formFields:any = {
      "accountNum": document.getElementById('accountNumber'),
      "cvv2": document.getElementById('securityCode'),
      "paypageRegistrationId": document.getElementById('response$paypageRegistrationId'),
      "bin": document.getElementById('response$bin')
    };

    formFields.accountNum.value = account;
    formFields.cvv2.value = cvv;
    var litleResponse:any = new Promise((resolve, reject) => {
    });
    new LitlePayPage().sendToLitle(litleRequest,
      formFields,
      (success:any) => {

        this.em.sendSuccessfulPayment.emit(success);
      },
      (error:any) => {
        console.log('error: ', error);
        this.em.sendPaymentError.emit(error);
      },
      (timeout:any) => {
        console.log('timeout: ', timeout);
        this.em.sendPaymentTimeout.emit(timeout);
      },
      15000);

   return litleResponse.then(
      (result:any) => {
      return result;
    });
  }

  generateOrderId() {
    var d = new Date().getTime();
    var id = 'xxxxxxxxxxxxxxxxxxxxxxxxx'.replace(/[x]/g,
      function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (r).toString(16);
      });
    return id;
  };

  litlePaypageErrorProcessor():any {
    this.litleInvalidCardCodes = [871, 872, 873, 874, 875, 876, 881, 882, 883];
    this.invalidCardMessage = "Invalid Card Number.";
    this.errorMessageToBeUsedWhenNotAnInvalidCard = "We are currently experiencing technical difficulties.  Please try again later.";
    // let generateErrorMessage = function (errorCode:number) {
    //   return this.litleInvalidCardCodes.indexOf(Number(errorCode)) > -1 ? this.invalidCardMessage : this.errorMessageToBeUsedWhenNotAnInvalidCard;
    //
    }
}
