import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {catchError, Observable} from "rxjs";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(private http: HttpClient) { }

  getFaqs(): Observable<any>{
    return this.http.get('data/faqs.json').pipe(
      catchError((e) => {
        return e;
      }),
    );
  }

  getTermsOfUse():Observable<any>{
    return this.http.get(environment.apiUrl+environment.treatmentApi+'files/public-documents/terms-of-use.html').pipe(
      catchError((e) => {
        return e;
      }),
    )
  }

  getPrivacyPolicy():Observable<any>{
    return this.http.get(environment.apiUrl+environment.treatmentApi+'files/public-documents/privacy-policy.html').pipe(
      catchError((e) => {
        return e;
      }),
    )
  }
}
