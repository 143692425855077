export class MemberModel {
  constructor(
    public firstName:string,
    public lastName:string,
    public birthDate:string,
    public phoneNumber:string,
    public emailAddress:string,
    public isPrimary:boolean,
  ) {
  }
}
