<div class="row mt-3 mb-3">
  <div class="col-4 col-lg-4 text-center" [ngClass]="currentStep === 1 ? 'selected' : 'not-selected'">
    1. Choose Plan
    <div class="green-bar mt-3" *ngIf="currentStep === 1"></div>
<!--    <div class="thin-bar mt-3" *ngIf="currentStep !== 1"></div>-->
  </div>
  <div class="col-4 col-lg-4 text-center" [ngClass]="currentStep === 2 ? 'selected' : 'not-selected'">
    2. Applicant Information
    <div class="green-bar mt-3" *ngIf="currentStep === 2"></div>
<!--    <div class="thin-bar mt-3" *ngIf="currentStep !== 2"></div>-->
  </div>
  <div class="col-4 col-lg-4 text-center" [ngClass]="currentStep === 3 ? 'selected' : 'not-selected'">
    3. Purchase Plan
    <div class="green-bar mt-3" *ngIf="currentStep === 3"></div>
<!--    <div class="thin-bar mt-3" *ngIf="currentStep !== 3"></div>-->
  </div>
</div>
