import { AbstractControl, FormControl } from '@angular/forms';
import * as moment from 'moment';

/**
 * The validator that checks to see if the phone is valid.
 *
 * @export
 * @param {AbstractControl} control
 * @returns {{ [key: string]: any }}
 */
export function phoneValidator(
  control: AbstractControl
){
  var phoneRe = /^[2-9][0-8]\d{1}[2-9](?![1]{2})\d{2}\d{4}$/;
  var internationalPhoneRe = /^\+(?:[0-9] ?){6,14}[0-9]$/;
  if (control.value !== null && control.value !== undefined && control.value.length > 0) {
    var digits = control.value.replace(/\D/g, "");
    if (!phoneRe.test(digits) && !internationalPhoneRe.test(digits)) {
      return {
        invalidPhone: true
      }
    }
  }

  return null;
}

export function ValidateBirthdate(control: AbstractControl) {
  if (!control.value) {
    return null;
  }
  const now = moment(moment.now())
  const date = moment(control.value, 'MM/DD/YYYY', true) as moment.Moment;

  if(date > now){
    return {
      invalidDate: true,
    };
  }

  // if(moment().diff(date,'years',false) <= 17){
  //   return {
  //     notAnAdult: true,
  //   }
  // }

  if (date.isValid()) {
    return null;
  }

  return {
    invalidDate: true,
  };
}

export function ValidateEmail(control: AbstractControl) {
  if (!control.value) {
      return null;
  }
  // tslint:disable-next-line: max-line-length
  const valid = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(control.value);

  if (valid) {
      return null;
  }

  return { invalidEmail: true };
}

export function zipValidator(
  control: AbstractControl
){
  if (!control.value) {
    return null;
  }
  var regex = /^\d{5}(?:[-\s]\d{4})?$/;
  if (control.value.length > 0 && !regex.test(control.value)) {
    return {
      invalidZip: true
    }
  }
return  null ;
}

export function isAMinor(control: AbstractControl) {
  if (!control.value) {
    return null;
  }
  const now = moment(moment.now())
  const date = moment(control.value, 'MM/DD/YYYY', true) as moment.Moment;

  if(moment().diff(date,'years',false) <= 17){
    console.log('yrs; ',moment().diff(date,'years',false));
    return {
      notAnAdult: true,
    }
  } else if(moment().diff(date,'years',false) > 17){
    console.log('yrs2; ',moment().diff(date,'years',false));
    return  null;
  } else {
    return null;
  }

}
